<script setup>
import phoneImg from "~/assets/images/statics/phone.jpg";

useHead({
  title: "Leasing samochodów dla firm - Oferta - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
});

const backend = useBackend();
const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))

</script>

<template>
  <Container>
    <div class="flex flex-col lg:flex-row pb-12">
      <div class="basis-3/5 shrink-0 flex-grow-0 order-2 lg:order-1">
        <div class="text-3xl text-center lg:text-left lg:text-5xl text-vehis-red">
          <h1>
            <span class="mb-6">SAMOCHÓD
              <span class="font-light">dla przedsiębiorcy</span>
            </span>
            <span class="block"><span class="font-light">jest</span> taniej!</span>
          </h1>
        </div>
        <div class="text-xl text-left leading-normal pt-8 lg:pt-16 font-light space-y-4 lg:pl-6">
          <div>
            <IconBulletGray class="mr-2" />
            Samochody z
            <b>wysokimi rabatami</b> dla przedsiębiorców
          </div>
          <div>
            <IconBulletGray class="mr-2" />
            Opłata wstępna od
            <b>1%</b>
          </div>
          <div>
            <IconBulletGray class="mr-2" />
            Uproszczona procedura bez dokumentów finansowych i
            <b>decyzja w 15 minut</b>
          </div>
          <div>
            <IconBulletGray class="mr-2" />
            Możliwość wykupu, zwrotu lub wymiany, także przed końcem umowy
          </div>
        </div>
      </div>
      <div class="basis-2/5 shrink-0 flex-grow-0 order-1 lg:order-2 flex items-center">
        <img src="@/assets/images/statics/entrepreneur-car.jpg" alt="cars">
      </div>
    </div>

    <StaticSeparator />

    <StaticHeader subheader="Sfinansowaliśmy już kilka tysięcy samochodów. Dzięki temu możemy Tobie zaproponować najbardziej atrakcyjną cenę na rynku.">Konkurencyjne ceny</StaticHeader>

    <div class="text-center text-lg font-light">Jeżeli masz już ofertę - wyślij ją do nas, a my ten sam samochód zaoferujemy Ci taniej.</div>

    <div class="lg:mx-20 my-10">
      <FormContact standalone />
    </div>

    <div class="flex"> <!-- todo -->
      <div class="basis-1/12 shrink-0 flex-grow-0"></div>
      <div class="basis-7/12">

      </div>
      <div class="basis-5/12">

      </div>
      <div class="basis-1/12 shrink-0 flex-grow-0"></div>
    </div>

    <StaticSeparator class="w-1/3" />

    <div class="flex flex-col lg:flex-row lg:gap-24 gap-9 lg:mx-6">
      <StaticArticleBox class="basis-1/2" header="Ubezpieczenie na raty"
                        preheader="Oferta specjalna"
      >
        <div class="font-light">
          W VEHIS możesz rozłożyć koszt polisy ubezpieczania komunikacyjnego i GAP na dogodne raty. Dotyczy również polis zakupionych spoza naszej oferty.
        </div>
      </StaticArticleBox>
      <StaticArticleBox class="basis-1/2" header="Dorzucamy gwarancję wysokości składki"
                        preheader="Oferta specjalna"
      >
        <div class="font-light">
          Przez cały okres finansowania Twoje składki za ubezpieczenia komunikacyjne i ubezpieczenie GAP nie ulegną zmianie bez względu na inflację, wzrost cen polis w towarzystw ubezpieczeniowych, czy ilość szkód, które naprawisz z odszkodowania.
        </div>
      </StaticArticleBox>
    </div>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>Wybierając VEHIS masz pewność samochodu</StaticHeader>


    <SlideControl ref="refControl">
      <SlideItem>
        <div class="basis-1/4 border border-gray-400 bg-white p-6 rounded lg:min-w-0 h-60">
          <div>
            <div class="pb-4 pt-1 pl-2">
              <IconPoland />
            </div>
            <div class="font-light">Samochody z polskiej sieci dealerskiej.</div>
          </div>
        </div>
      </SlideItem>

      <SlideItem>
        <div class="basis-1/4 border border-gray-400 bg-white p-6 rounded lg:min-w-0 h-60">
          <div>
            <div class="pb-4 pt-1 pl-2">
              <IconAward />
            </div>
            <div class="font-light">Samochody nowe z pełną gwarancją producenta.</div>
          </div>
        </div>
      </SlideItem>
      <SlideItem>
        <div class="basis-1/4 border border-gray-400 bg-white p-6 rounded lg:min-w-0 h-60">
          <div>
            <div class="pb-4 pt-1 pl-2">
              <IconExpertise />
            </div>
            <div class="font-light">Niezależnie od sposobu zakończenia umowy, nie obciążymy Cię opłatami za nadprzebieg.</div>
          </div>
        </div>

      </SlideItem>
      <SlideItem>
        <div class="basis-1/4 border border-gray-400 bg-white p-6 rounded lg:min-w-0 h-60">
          <div>
            <div class="pb-4 pt-1 pl-2">
              <IconHouse />
            </div>
            <div class="font-light">Odbiór u dealera lub dostawa pod dom.</div>
          </div>
        </div>
      </SlideItem>
    </SlideControl>
    <SlideNavigation ref="refSlideNavigation"
                     class="-mt-4"
                     :items="slides.slidesCount.value"
                     @changed="(slide) => slides.scrollToSlide(slide)"
    />

    <StaticSeparator class="w-1/3" />

    <StaticHeader
      subheader="W finansowaniu VEHIS z łatwością dopasujesz wysokość miesięcznego zobowiązania do Twojego budżetu. Wystarczy właściwie dobrać okres umowy, wysokość opłaty wstępnej i wartość wykupu."
    >Rata dopasowana do Twoich możliwości
    </StaticHeader>

    <div class="flex flex-col lg:flex-row w-full justify-center items-center gap-8 lg:gap-32 py-12">
      <div class="flex flex-col lg:flex-row">
        <div class="grow flex flex-col items-center lg:items-start">
          <div class="text-vehis-red">okres umowy</div>
          <div class="text-2xl leading-relaxed py-2">36 | 48 | 60 miesięcy</div>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row">
        <div class="grow flex flex-col items-center lg:items-start">
          <div class="text-vehis-red">opłata wstępna</div>
          <div class="text-2xl leading-relaxed py-2">od 1%</div>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row">
        <div class="grow flex flex-col items-center lg:items-start">
          <div class="text-vehis-red">wartość wykupu</div>
          <div class="text-2xl leading-relaxed py-2">od 1% do 50%</div>
        </div>
      </div>

    </div>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>Program wsparcia serwisowego</StaticHeader>

    <StaticArticleBox class="mx-auto w-10/12"
                      preheader="Program wsparcia serwisowego"
                      header="1500 autoryzowanych oraz niezależnych warsztatów"
                      :src="phoneImg"
                      buttonLabel="Lista partnerów serwisowych"
                      :href="backend.getDownloadLinkForFile('services')"
                      target="_blank"
                      right-side-image
    >
      <div class="font-light">
        W ramach
        <b>Programu wsparcia serwisowego</b> współpracujemy z szeroką listą autoryzowanych i niezależnych partnerów serwisowych, którzy przy naszym wsparciu dbają o
        <b>sprawność techniczną samochodów</b> naszych klientów.
      </div>
    </StaticArticleBox>

    <StaticSeparator class="w-1/3" />

    <StaticHeader subheader="Nie zastanawiaj się nad tym, co wydarzy się za kilka lat."
                  subheader2="W finansowaniu VEHIS o sposobie zakończenia umowy zdecydujesz we właściwym dla Ciebie momencie.">Zakończenie umowy? Zdecydujesz później!
    </StaticHeader>

    <div class="flex flex-col lg:flex-row gap-10 lg:gap-6 lg:mx-20 mt-6">
      <div class="basis-1/4 flex flex-col items-center relative">
        <div class="w-20 h-20 rounded-full bg-gray-100 text-2xl font-normal flex justify-center items-center">1</div>
        <div class="pt-4 px-6 text-center font-light">Możesz wykupić</div>
        <div class="font-extralight p-4 text-center">
          Na koniec umowy wpłać ustaloną na początku wartość wykupu i przejmij samochód na własność.
        </div>
      </div>
      <div class="basis-1/4 flex flex-col items-center relative">
        <div class="w-20 h-20 rounded-full bg-gray-100 text-2xl font-normal flex justify-center items-center">2</div>
        <div class="pt-4 px-6 text-center font-light">Możesz zwrócić</div>
        <div class="font-extralight p-4 text-center">
          Oddaj samochód do VEHIS bez spłaty wartości wykupu.
        </div>
      </div>
      <div class="basis-1/4 flex flex-col items-center relative">
        <div class="w-20 h-20 rounded-full bg-gray-100 text-2xl font-normal flex justify-center items-center">3</div>
        <div class="pt-4 px-6 text-center font-light">Możesz przedłużyć umowę</div>
        <div class="font-extralight p-4 text-center">
          Rozłóż wartość wykupu na dogodne raty i przedłuż z nami umowę.
        </div>
      </div>
      <div class="basis-1/4 flex flex-col items-center relative">
        <div class="w-20 h-20 rounded-full bg-gray-100 text-2xl font-normal flex justify-center items-center">4</div>
        <div class="pt-4 px-6 text-center font-light">Możesz zmienić plany przed końcem umowy</div>
        <div class="font-extralight p-4 text-center">
          Jeżeli przed końcem umowy zmienią się Twoje potrzeby, to w każdej chwili i bez podawania przyczyny możesz zwrócić samochód do VEHIS i na przykład wymienić go na inny model.
        </div>
      </div>
    </div>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>Dodatkowe korzyści</StaticHeader>

    <StaticTwoColumn class="pb-14">
      <template v-slot:one>
        <div class="space-y-4">
          <div>Zoptymalizujesz podatki, ponieważ całą ratę zaliczysz w koszty.</div>
          <div>Nie obniżasz płynności finansowej.</div>
          <div>Samochód spłacasz w ratach, więc kapitał możesz przeznaczyć na inne inwestycje.</div>
        </div>
      </template>
      <template v-slot:two>
        <div class="space-y-4">
          <div>Dzięki uproszczonej procedurze, finansowanie w formie leasingu jest łatwiej dostępne niż w innych formach finansowania (np. kredyt).</div>
          <div>
            <b>W procedurze uproszczonej dla samochodów do wartości 250.000 zł nie wymagamy dokumentów finansowych!</b>
          </div>
        </div>
      </template>
    </StaticTwoColumn>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>Często zadawane pytania</StaticHeader>

    <div class="lg:mx-20 mt-6 mb-10">
      <StaticFaqItem question="Jakie dokumenty będą potrzebne do złożenia wniosku o finansowanie?">
        Jeżeli prowadzisz działalność gospodarczą wystarczy, że wyślesz do nas prosty wniosek, w którym m.in. podasz nam numer NIP swojej firmy, dane z dokumentu tożsamości i wyrazisz zgodę na weryfikację w bazach (może być telefonicznie). Jeżeli nie prowadzisz działalności oprócz wniosku i zgód poprosimy Cię dodatkowo o przesłanie oświadczenia o miejscu zatrudnienia i dochodzie.
      </StaticFaqItem>
      <StaticFaqItem question="Jak długo muszę prowadzić działalność, żeby dostać finansowanie w VEHIS?">
        W VEHIS finansujemy startupy już od pierwszego dnia działalności gospodarczej.
      </StaticFaqItem>
      <StaticFaqItem question="Czy muszę być obywatelem Polski, żeby sfinansować samochód w VEHIS?">
        Nie musisz być obywatelem Polski. Wystarczy, że dysponujesz ważną kartą pobytu w Polsce lub posiadasz spółkę zarejestrowaną w Polsce.
      </StaticFaqItem>
      <StaticFaqItem question="Na czym polega możliwość wymiany lub zwrotu samochodu przed końcem umowy?">
        W naszej umowie leasingu wprowadziliśmy możliwość jej przedterminowego zakończenia bez podawania przyczyny. W dowolnym momencie możesz zgłosić do nas chęć rozliczenia i zakończenia umowy. Zestawimy wówczas Twoje zobowiązanie z prognozowaną wartością samochodu (wstępna wycena na bazie Eurotax). Jeżeli pozostała do spłaty kwota przewyższy wartość samochodu, to wpłacisz nam różnicę w formie kaucji i możesz oddać samochód do VEHIS. Od tego momentu nie będziemy wystawiali kolejnych faktur i wystawimy Twój samochód na sprzedaż. Ty też możesz pozyskać Klienta we własnym zakresie! Po sprzedaży nastąpi rozliczenie, w którym dodatkowo uwzględniamy zwrot z niewykorzystanej części ubezpieczenia. Jeżeli po finalnym rozliczeniu zostanie nadwyżka to w całości Ci ją wypłacimy. Jeżeli przychód ze sprzedaży i zwrot z ubezpieczenia nie pokryją wartości zobowiązania będziesz musiał dopłacić różnicę (w tym celu wykorzystamy kaucję, o której piszemy powyżej). Po zamknięciu całej transakcji możesz wziąć leasing na inny interesujący Cię samochód lub po prostu zakończyć naszą współpracę.
      </StaticFaqItem>
      <StaticFaqItem question="Czy mogę sfinansować samochód spoza oferty VEHIS?">
        Choć oferta VEHIS obejmuje wszystkie najpopularniejsze segmenty i większość marek dostępnych w Polsce to może się zdarzyć, że swój upragniony samochód znajdziesz u innego dostawcy. Oczywiście my nadal bardzo chętnie go sfinansujemy! W VEHIS finansujemy samochody osobowe i dostawcze do 3,5 t, nowe i używane do 5 lat z maksymalnym przebiegiem 150 tys. km. Samochód musi mieć dowód zakupu w postaci faktury VAT lub faktury VAT marża.
      </StaticFaqItem>
    </div>
  </Container>
  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
